import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../_metronic/layout/core';
import { toast } from 'react-toastify';
import { Loader } from '../../../_metronic/helpers';
import { 
    Card, 
    CardBody, 
    CardHeader, 
    CardTitle,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane 
} from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    clearState, 
    getSmtpSettings, 
    patchSmtpSettings, 
    postSmtpSettings, 
    postTestEamil
} from './settingActions';

const EmailSettings = (props) => {
    const intl = useIntl();
    const [activeTab, setActiveTab] = useState('');
    const [testEmail, setTestEmail] = useState({
        recipientEmail: '',
        subject: '',
        body: ''
    });

    // Redux state
    const {
        smtpSettings,
        loading,
        success,
        error
    } = props;

    // Redux actions
    const {
        clearState,
        getSmtpSettings,
        patchSmtpSettings,
        postSmtpSettings,
        postTestEamil
    } = props;

    useEffect(() => {
        getSmtpSettings();
        return () => {
            clearState();
        }
    }, []);

    useEffect(() => {
        if (smtpSettings?.length > 0 && !activeTab) {
            setActiveTab(smtpSettings[0].id.toString());
        }
    }, [smtpSettings]);

    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({ id: success }));
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(intl.formatMessage({ id: error }));
        }
    }, [error]);

    const breadcrumbs = [
        {
            title: intl.formatMessage({ id: 'SETTING.MANAGEMENT.TITLE' }),
            path: '/setting-management/email',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ];

    const validationSchema = Yup.object().shape({
        smtpAddress: Yup.string()
            .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
        smtpPort: Yup.number()
            .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
            .min(1, intl.formatMessage({ id: 'VALIDATION.MIN_VALUE' }))
            .max(65535, intl.formatMessage({ id: 'VALIDATION.MAX_VALUE' })),
        enableSsl: Yup.boolean(),
        emailFrom: Yup.string()
            .email(intl.formatMessage({ id: 'VALIDATION.WRONG_FORMAT' }))
            .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
        username: Yup.string()
            .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
        password: Yup.string()
    });

    const emptySmtpSetting = {
        smtpAddress: '',
        smtpPort: '',
        enableSsl: false,
        emailFrom: '',
        username: '',
        password: ''
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: activeTab === 'new' 
            ? emptySmtpSetting 
            : smtpSettings?.find(setting => setting.id.toString() === activeTab) || emptySmtpSetting,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (activeTab !== 'new') {
                patchSmtpSettings(activeTab, {
                    smtpAddress: {
                        set: true,
                        value: values.smtpAddress
                    },
                    smtpPort: {
                        set: true,
                        value: values.smtpPort
                    },
                    enableSsl: {
                        set: true,
                        value: values.enableSsl
                    },
                    emailFrom: {
                        set: true,
                        value: values.emailFrom
                    },
                    username: {
                        set: true,
                        value: values.username
                    },
                    password: {
                        set: values.password !== '' && values.password !== undefined,
                        value: values.password
                    }
                });
            } else {
                postSmtpSettings(values);
            }
        },
    });

    const renderElement = (label, name, type = 'text') => {
        const isTouched = formik.touched[name];
        const errorMessage = formik.errors[name];
        
        return (
            <div className='fv-row mb-0'>
                <label className='form-label fs-6 fw-bolder mb-3 mt-5'>
                    {intl.formatMessage({ id: label })}
                </label>
                {type === 'checkbox' ? (
                    <div className="form-check">
                        <input
                            type='checkbox'
                            className='form-check-input'
                            id={name}
                            {...formik.getFieldProps(name)}
                            checked={formik.values[name]}
                        />
                    </div>
                ) : (
                    <input
                        type={type}
                        className='form-control'
                        id={name}
                        {...formik.getFieldProps(name)}
                    />
                )}
                {isTouched && errorMessage && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{errorMessage}</div>
                    </div>
                )}
            </div>
        );
    };

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const tabStyle = {
        cursor: 'pointer'
    };
    
    const handleTestEmailSubmit = (e) => {
        e.preventDefault();
        postTestEamil(activeTab, testEmail);
    };

    return (
        <>
            <PageTitle breadcrumbs={breadcrumbs}>
                {intl.formatMessage({ id: 'SETTING.SMTP_SETTINGS' })}
            </PageTitle>
            
            <Card className="mb-5">
                <CardHeader>
                    <CardTitle>
                        <div className="d-flex justify-content-between align-items-center">
                            <h3>{intl.formatMessage({ id: 'SETTING.SMTP_SETTINGS' })}</h3>
                        </div>
                    </CardTitle>
                </CardHeader>
                <CardBody>
                    <Nav tabs>
                        {smtpSettings?.map((smtp) => (
                            <NavItem key={smtp.id}>
                                <NavLink
                                    style={tabStyle}
                                    className={activeTab === smtp.id.toString() ? 'active' : ''}
                                    onClick={() => toggle(smtp.id.toString())}
                                >
                                    {smtp.emailFrom}
                                </NavLink>
                            </NavItem>
                        ))}
                        <NavItem>
                            <NavLink
                                style={tabStyle}
                                className={activeTab === 'new' ? 'active' : ''}
                                onClick={() => toggle('new')}
                            >
                                {intl.formatMessage({ id: 'GENERAL.NEW' })}
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={activeTab}>
                        {smtpSettings?.map((smtp) => (
                            <TabPane key={smtp.id} tabId={smtp.id.toString()}>
                                <form
                                    onSubmit={formik.handleSubmit}
                                    className='form'
                                    noValidate
                                >
                                    <div className='row mb-1'>
                                        <div className='col-md-6'>
                                            {renderElement('SETTING.SMTP_ADDRESS', 'smtpAddress')}
                                        </div>
                                        <div className='col-md-6'>
                                            {renderElement('SETTING.SMTP_PORT', 'smtpPort', 'number')}
                                        </div>
                                    </div>

                                    <div className='row mb-1'>
                                        <div className='col-md-6'>
                                            {renderElement('SETTING.EMAIL_FROM', 'emailFrom', 'email')}
                                        </div>
                                        <div className='col-md-6'>
                                            {renderElement('SETTING.ENABLE_SSL', 'enableSsl', 'checkbox')}
                                        </div>
                                    </div>

                                    <div className='row mb-1'>
                                        <div className='col-md-6'>
                                            {renderElement('SETTING.USERNAME', 'username')}
                                        </div>
                                        <div className='col-md-6'>
                                            {renderElement('SETTING.PASSWORD', 'password', 'password')}
                                        </div>
                                    </div>

                                    <div className='d-flex mt-5'>
                                        <button
                                            type='submit'
                                            className='btn btn-primary me-2 px-6'
                                        >
                                            {intl.formatMessage({ id: 'GENERAL.SUBMIT' })}
                                        </button>
                                    </div>
                                </form>
                            </TabPane>
                        ))}
                        <TabPane tabId="new">
                            <form
                                onSubmit={formik.handleSubmit}
                                className='form'
                                noValidate
                            >
                                <div className='row mb-1'>
                                    <div className='col-md-6'>
                                        {renderElement('SETTING.SMTP_ADDRESS', 'smtpAddress')}
                                    </div>
                                    <div className='col-md-6'>
                                        {renderElement('SETTING.SMTP_PORT', 'smtpPort', 'number')}
                                    </div>
                                </div>

                                <div className='row mb-1'>
                                    <div className='col-md-6'>
                                        {renderElement('SETTING.EMAIL_FROM', 'emailFrom', 'email')}
                                    </div>
                                    <div className='col-md-6'>
                                        {renderElement('SETTING.ENABLE_SSL', 'enableSsl', 'checkbox')}
                                    </div>
                                </div>

                                <div className='row mb-1'>
                                    <div className='col-md-6'>
                                        {renderElement('SETTING.USERNAME', 'username')}
                                    </div>
                                    <div className='col-md-6'>
                                        {renderElement('SETTING.PASSWORD', 'password', 'password')}
                                    </div>
                                </div>

                                <div className='d-flex mt-5'>
                                    <button
                                        type='submit'
                                        className='btn btn-primary me-2 px-6'
                                    >
                                        {intl.formatMessage({ id: 'GENERAL.SUBMIT' })}
                                    </button>
                                </div>
                            </form>
                        </TabPane>
                    </TabContent>
                </CardBody>
            </Card>

            <Card>
                <CardHeader>
                    <CardTitle>
                        <h3>{intl.formatMessage({ id: 'SETTING.TEST_EMAIL' })}</h3>
                    </CardTitle>
                </CardHeader>
                <CardBody>
                    <form onSubmit={handleTestEmailSubmit} className="form">
                        <div className="row mb-6">
                            <div className="col-md-6">
                                <label className="form-label fs-6 fw-bolder mb-3">
                                    {intl.formatMessage({ id: 'SETTING.RECIPIENT_EMAIL' })}
                                </label>
                                <input
                                    type="email"
                                    className="form-control"
                                    value={testEmail.recipientEmail}
                                    onChange={(e) => setTestEmail(prev => ({
                                        ...prev,
                                        recipientEmail: e.target.value
                                    }))}
                                    required
                                />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label fs-6 fw-bolder mb-3">
                                    {intl.formatMessage({ id: 'SETTING.EMAIL_SUBJECT' })}
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={testEmail.subject}
                                    onChange={(e) => setTestEmail(prev => ({
                                        ...prev,
                                        subject: e.target.value
                                    }))}
                                    required
                                />
                            </div>
                        </div>

                        <div className="row mb-6">
                            <div className="col-12">
                                <label className="form-label fs-6 fw-bolder mb-3">
                                    {intl.formatMessage({ id: 'SETTING.EMAIL_BODY' })}
                                </label>
                                <div className="mb-3">
                                    <textarea
                                        className="form-control"
                                        value={testEmail.body}
                                        onChange={(e) =>
                                            setTestEmail((prev) => ({
                                                ...prev,
                                                body: e.target.value,
                                            }))
                                        }
                                        style={{ minHeight: '200px', maxHeight: '400px', overflowY: 'auto' }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row mb-6">
                            <div className="col-12">
                                <label className="form-label fs-6 fw-bolder mb-3">
                                    {intl.formatMessage({ id: 'SETTING.EMAIL_PREVIEW' })}
                                </label>
                                <div 
                                    className="border rounded p-4"
                                    style={{ 
                                        minHeight: '200px',
                                        maxHeight: '400px',
                                        overflowY: 'auto'
                                    }}
                                    dangerouslySetInnerHTML={{ __html: testEmail.body }}
                                />
                            </div>
                        </div>

                        <div className="d-flex justify-content-end mt-5">
                            <button
                                type="submit"
                                className="btn btn-primary px-6"
                                disabled={loading || !testEmail.recipientEmail || !testEmail.subject || !testEmail.body}
                            >
                                {intl.formatMessage({ id: 'SETTING.SEND_TEST_EMAIL' })}
                            </button>
                        </div>
                    </form>
                </CardBody>
            </Card>
            {loading && <Loader />}
        </>
    );
};

const mapStateToProps = state => {
    return {
        smtpSettings: state.Setting.smtpSettings,
        loading: state.Setting.loading,
        success: state.Setting.success,
        error: state.Setting.error
    };
};

const mapActionsToProps = {
    clearState,
    getSmtpSettings,
    patchSmtpSettings,
    postSmtpSettings,
    postTestEamil
};

export default connect(mapStateToProps, mapActionsToProps)(EmailSettings);