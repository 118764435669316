/* SETTING */
const actionTypes = Object.freeze({
    CLEAR_STATE: 'SETTINGS/CLEAR_STATE',

    GET_COMPANY_SETTINGS: 'SETTINGS/GET_COMPANY_SETTINGS',
    GET_COMPANY_SETTINGS_SUCCESS : 'SETTINGS/GET_COMPANY_SETTINGS_SUCCESS',
    POST_COMPANY_SETTINGS: 'SETTINGS/POST_COMPANY_SETTINGS',
    POST_COMPANY_SETTINGS_SUCCESS: 'SETTINGS/POST_COMPANY_SETTINGS_SUCCESS',

    GET_INVOICE_SETTINGS: 'SETTINGS/GET_INVOICE_SETTINGS',
    GET_INVOICE_SETTINGS_SUCCESS : 'SETTINGS/GET_INVOICE_SETTINGS_SUCCESS',
    POST_INVOICE_SETTINGS: 'SETTINGS/POST_INVOICE_SETTINGS',
    POST_INVOICE_SETTINGS_SUCCESS: 'SETTINGS/POST_INVOICE_SETTINGS_SUCCESS',

    GET_QUOTATION_SETTINGS: 'SETTINGS/GET_QUOTATION_SETTINGS',
    GET_QUOTATION_SETTINGS_SUCCESS : 'SETTINGS/GET_QUOTATION_SETTINGS_SUCCESS',
    POST_QUOTATION_SETTINGS: 'SETTINGS/POST_QUOTATION_SETTINGS',
    POST_QUOTATION_SETTINGS_SUCCESS: 'SETTINGS/POST_QUOTATION_SETTINGS_SUCCESS',

    GET_LOOKUP_VALUES:'SETTINGS/GET_LOOKUP_VALUES',

    GET_SMTP_SETTINGS: 'SETTINGS/GET_SMTP_SETTINGS',
    GET_SMTP_SETTINGS_SUCCESS: 'SETTINGS/GET_SMTP_SETTINGS_SUCCESS',
    GET_SMTP_SETTINGS_FAILURE: 'SETTINGS/GET_SMTP_SETTINGS_FAILURE',
    
    PATCH_SMTP_SETTINGS: 'SETTINGS/PATCH_SMTP_SETTINGS',
    PATCH_SMTP_SETTINGS_SUCCESS: 'SETTINGS/PATCH_SMTP_SETTINGS_SUCCESS',
    PATCH_SMTP_SETTINGS_FAILURE: 'SETTINGS/PATCH_SMTP_SETTINGS_FAILURE',
    
    POST_SMTP_SETTINGS: 'SETTINGS/POST_SMTP_SETTINGS',
    POST_SMTP_SETTINGS_SUCCESS: 'SETTINGS/POST_SMTP_SETTINGS_SUCCESS',
    POST_SMTP_SETTINGS_FAILURE: 'SETTINGS/POST_SMTP_SETTINGS_FAILURE',
    
    POST_TEST_EMAIL: 'SETTINGS/POST_TEST_EMAIL',
    POST_TEST_EMAIL_SUCCESS: 'SETTINGS/POST_TEST_EMAIL_SUCCESS',
    POST_TEST_EMAIL_FAILURE: 'SETTINGS/POST_TEST_EMAIL_FAILURE',
});

export default actionTypes;