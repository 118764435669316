// @flow
import types from './settingConstants';
import {getAsync, patchAsync, postAsync} from '../../../_metronic/helpers/httpClient';

export const clearState = () => async dispatch => {
    dispatch({
        type: types.CLEAR_STATE
    })
}

export const getCompanySetting = () => async dispatch => {
    dispatch({
        type: types.GET_COMPANY_SETTINGS,
    })
    const response = await getAsync(`/settings/company`);
    dispatch({
        type: types.GET_COMPANY_SETTINGS_SUCCESS,
        payload: response
    })
}

export const postCompanySetting = (values) => async dispatch => {
    dispatch({
        type: types.POST_COMPANY_SETTINGS,
    })
    const response = await postAsync(`/settings/company`,values);
    dispatch({
        type: types.POST_COMPANY_SETTINGS_SUCCESS,
        payload: response
    })
}

export const getInvoiceSetting = () => async dispatch => {
    dispatch({
        type: types.GET_INVOICE_SETTINGS,
    })
    const response = await getAsync(`/settings/invoice`);
    dispatch({
        type: types.GET_INVOICE_SETTINGS_SUCCESS,
        payload: response
    })
}

export const postInvoiceSetting = (values) => async dispatch => {
    dispatch({
        type: types.POST_INVOICE_SETTINGS,
    })
    const response = await postAsync(`/settings/invoice`,values);
    dispatch({
        type: types.POST_INVOICE_SETTINGS_SUCCESS,
        payload: response
    })
}

export const getLookupValues = (type) => async dispatch => {

    const response = await getAsync(`/lookup/${type}/values`);
    dispatch({
        type: types.GET_LOOKUP_VALUES,
        payload: response,
    })
}

export const getQuotationSetting = () => async dispatch => {
    dispatch({
        type: types.GET_QUOTATION_SETTINGS,
    })
    const response = await getAsync(`/settings/quotation`);
    dispatch({
        type: types.GET_QUOTATION_SETTINGS_SUCCESS,
        payload: response
    })
}

export const postQuotationSetting = (values) => async dispatch => {
    dispatch({
        type: types.POST_QUOTATION_SETTINGS,
    })
    const response = await postAsync(`/settings/quotation`, values);
    dispatch({
        type: types.POST_QUOTATION_SETTINGS_SUCCESS,
        payload: response
    })
}

export const getSmtpSettings = () => async (dispatch) => {
    dispatch({ type: types.GET_SMTP_SETTINGS });
    
    const response = await getAsync('/settings/email/smtp');
    
    dispatch({
        type: types.GET_SMTP_SETTINGS_SUCCESS,
        payload: response
    });
};

export const patchSmtpSettings = (id, settings) => async (dispatch) => {
    dispatch({ type: types.PATCH_SMTP_SETTINGS });

    const response = await patchAsync(`/settings/email/smtp/${id}`, settings);
    
    if (response.isSuccess) {
        dispatch({
            type: types.PATCH_SMTP_SETTINGS_SUCCESS,
            payload: response
        })
    } else {
        dispatch({
            type: types.PATCH_SMTP_SETTINGS_FAILURE,
            payload: response
        })
    }
};

export const postSmtpSettings = (settings) => async (dispatch) => {
    dispatch({ type: types.POST_SMTP_SETTINGS });
    
    const response = await postAsync('/settings/email/smtp', settings);
    
    if (response.isSuccess) {
        dispatch({
            type: types.POST_SMTP_SETTINGS_SUCCESS,
            payload: response
        })
        getSmtpSettings();
    } else {
        dispatch({
            type: types.POST_SMTP_SETTINGS_FAILURE,
            payload: response
        })
    }

    return response;
};

export const postTestEamil = (id, testEmail) => async (dispatch) => {
    dispatch({ type: types.POST_TEST_EMAIL });
    
    const response = await postAsync(`/settings/email/smtp/${id}/test`, testEmail);
    
    if (response.isSuccess) {
        dispatch({
            type: types.POST_TEST_EMAIL_SUCCESS,
            payload: response
        })
    } else {
        dispatch({
            type: types.POST_TEST_EMAIL_FAILURE,
            payload: response
        })
    }

    return response;
};